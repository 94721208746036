export const configurationEnvironment = {
  GOOGLE_ANALYTICS: {
    domain: 'auto',
    GTM: 'GTM-KNFJWXT'
  },
  INTEGRATIONS: {
    BULLHORN: {},
    ERECRUIT: {},
    SALESFORCE: {
      client_id: ''
    },
    SALESFORCE_ISV: {
      client_id: ''
    },
    TARGET_RECRUIT: {},
    JOBADDER: {
      client_id: 'ag4ccam3hphu3bxkelfdwpnzwe'
    },
    JOBDIVA: {},
    GREENHOUSE: {}
  },
  STRIPE: {
    PUBLISHABLE_KEY: 'pk_live_vQlVAdmpJYqIeF5wLjE1uwCd',
    CARD: {}
  }
};
