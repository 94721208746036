import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { configurationEnvironment } from '@configuration/environment/environment.configuration';
import { environment } from '@environment/environment';
import { TranslateService } from '@ngx-translate/core';
import { hexToRGB } from '@shared/helpers/utils.helper';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentsService {
  @LocalStorage() public lng: string;

  @SessionStorage() private hiringFirm: IHiringFirm;

  public HOSTNAME = String(this._commonEnvironments.getHostname());
  public IS_VITAY = Boolean(this.HOSTNAME.indexOf('vitay') > -1);

  constructor(
    @Inject(DOCUMENT) private readonly _document: any,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _translate: TranslateService
  ) {}

  public setLng(lng: string, isStoring: boolean = true) {
    lng = lng.match(/en|es|fr/) ? lng : 'en';

    this._translate.setDefaultLang(lng);
    this._translate.use(lng);

    if (isStoring) {
      this.lng = lng;
    }
  }

  public setStylesheet(settings?: ISetting) {
    let isUpdated = true;
    if (!this.IS_VITAY) {
      if (!settings) {
        isUpdated = false;
        settings = this.hiringFirm?.settings;
      }

      const colorsPrimary = settings?.colors_primary || '#1CB9C8';
      const colorsSecondary = settings?.colors_secondary || '#333333';
      const fontFamily =
        settings?.font_family || '"Open Sans", Arial, Helvetica, sans-serif';

      const isWhiteLabelEnable =
        settings?.is_plugins_white_label_enabled || false;

      if (isWhiteLabelEnable) {
        const head = this._document.getElementsByTagName('head')[0];

        const css = `
            html, body { font-family: ${fontFamily} !important; }
            .v-btn-primary { background-color: ${colorsPrimary} !important; }
            .v-btn-primary:not(.disabled):hover { background-color: ${colorsSecondary} !important; }
            .v-btn-outline-primary { color: ${colorsPrimary} !important; border-color: ${colorsPrimary} !important; }
            .v-btn-outline-primary:hover { background-color: ${colorsPrimary} !important; }
            a:hover { color: ${colorsPrimary} !important; }
            .page-item .page-link { color: ${colorsPrimary} !important; }
            .page-item.active .page-link { color: white !important; border-color: ${colorsPrimary} !important; background: ${colorsPrimary} !important; }
            .loading .spinner div { background-color: ${colorsPrimary} !important; }
            .navbar .v-nav-item:hover a i, .navbar .v-nav-item.active a i, .topbar .v-nav-item:hover .nav-link i, .topbar .v-nav-item.active .nav-link i { color: ${colorsPrimary} !important; }
            tabset .nav-pills .nav-link.active { background-color: ${hexToRGB(
              colorsPrimary,
              0.15
            )} !important; color: ${colorsPrimary} !important; }
            .v-alert-primary { color: ${colorsPrimary} !important; background-color: ${hexToRGB(
          colorsPrimary,
          0.4
        )} !important; }
            .integrations-card--isNotAvailable { background-color: ${colorsPrimary} !important; }
            .v-text-primary { color: ${colorsPrimary} !important; }
            .v-text--highlight { color: ${colorsPrimary} !important; }
            .v-table-hover tbody tr:hover { background-color: ${hexToRGB(
              colorsPrimary,
              0.05
            )} !important; }
            .form-control:focus { background-color: ${hexToRGB(
              colorsPrimary,
              0.1
            )} !important; border-color: ${colorsPrimary} !important; }
          `;

        const styleId = 'white-label-stylesheet';
        const style = this._document.createElement('style');
        style.type = 'text/css';
        style.id = styleId;

        style.appendChild(document.createTextNode(css));

        const isStyleExist = !!this._document.getElementById(styleId);

        if (!isStyleExist || isUpdated) {
          head.appendChild(style);
          this.hiringFirm = this.hiringFirm;
        }
      }
    }
  }

  public setScripts() {
    if (environment.IS_PRODUCTION) {
      const head = this._document.getElementsByTagName('head')[0];
      const body = this._document.getElementsByTagName('body')[0];

      const scriptGATag = this._document.createElement('script');
      scriptGATag.scriptGATag = 'text/javascript';

      let gaTagInnerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});`;
      gaTagInnerHTML += `var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;`;
      gaTagInnerHTML += `j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})`;
      gaTagInnerHTML += `(window, document, 'script', 'dataLayer', '${configurationEnvironment.GOOGLE_ANALYTICS.GTM}');`;

      let helpScoutTag = `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`;
      helpScoutTag += `window.Beacon('init', '2dd0e0c9-b62d-45d2-8811-e3f5fa17612d')`;
      gaTagInnerHTML += helpScoutTag;

      scriptGATag.innerHTML = gaTagInnerHTML;

      const noScriptGATag = this._document.createElement('noscript');
      noScriptGATag.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${configurationEnvironment.GOOGLE_ANALYTICS.GTM}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

      const beamerConfigTag = this._document.createElement('script');
      beamerConfigTag.innerHTML = `var beamer_config = { product_id : "qoBvPNpS53935", selector: ".beamerTrigger" };`;

      const beamerScript = this._document.createElement('script');
      beamerScript.type = 'text/javascript';
      beamerScript.src = 'https://app.getbeamer.com/js/beamer-embed.js';
      beamerScript.defer = 'defer';

      body.appendChild(beamerConfigTag);
      body.appendChild(beamerScript);

      body.appendChild(noScriptGATag);
      head.appendChild(scriptGATag);
    }
  }
}
